import { useMediaQuery } from 'react-responsive';
import type { ReactNode } from 'react';

import { breakpoints } from '@yoweb/ui/styles/utils';

type Sizes = keyof Omit<typeof breakpoints, '_'>;
type MediaProps = {
  minWidth?: Sizes;
  maxWidth?: Sizes;
  children?: ReactNode | ((matches: boolean) => ReactNode);
};

const useResponsive = ({ minWidth: min, maxWidth: max }: MediaProps): boolean =>
  useMediaQuery({
    minWidth: min && breakpoints[min],
    maxWidth: max && breakpoints[max] - 1,
  });

/** @deprecated use a CSS-based approach such as RenderOn.MobileOnly from '@yoweb/ui/components/RenderOn/RenderOn'; */
export const useMobileOnly = (): ReturnType<typeof useResponsive> =>
  useResponsive({ maxWidth: 'md' });

/** @deprecated use a CSS-based approach such as RenderOn.TabletOnly from '@yoweb/ui/components/RenderOn/RenderOn'; */
export const useTabletOnly = (): ReturnType<typeof useResponsive> =>
  useResponsive({ minWidth: 'md', maxWidth: 'lg' });

/** @deprecated use a CSS-based approach such as RenderOn.MobileAndTablet from '@yoweb/ui/components/RenderOn/RenderOn'; */
export const useMobileOrTablet = (): ReturnType<typeof useResponsive> =>
  useResponsive({ maxWidth: 'lg' });

/** @deprecated use a CSS-based approach such as RenderOn.Tablet from '@yoweb/ui/components/RenderOn/RenderOn'; */
export const useTablet = (): ReturnType<typeof useResponsive> => useResponsive({ minWidth: 'md' });

/** @deprecated use a CSS-based approach such as RenderOn from '@yoweb/ui/components/RenderOn/RenderOn'; */
export const useDesktopOnly = (): ReturnType<typeof useResponsive> =>
  useResponsive({ minWidth: 'lg' });

const Responsive = ({ minWidth, maxWidth, children }: MediaProps) => {
  const matches = useResponsive({ maxWidth, minWidth });
  if (typeof children === 'function') {
    return children(matches);
  }
  return matches ? children : null;
};

export default Responsive;
