import styled from 'styled-components';

import { Title } from '@yoweb/ui/components/typography';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { StaggerElements } from '@yoweb/ui/components/AnimateIn';

export type TextHeaderProps = {
  title: string | ReactNode;
  subtitle?: string;
  isInView: boolean;
};

/**
 * Header component to render centered title and subtitle for marketing pages
 */
export const TextHeader = ({ title, subtitle, isInView }: TextHeaderProps): JSX.Element => (
  <HeaderContainer>
    <StaggerElements isInView={isInView}>
      {typeof title === 'string' ? (
        <Title as="h1" size={{ _: 'lg', md: 'xl', lg: 'xxl' }} whiteSpace="pre-wrap">
          {title}
        </Title>
      ) : (
        title
      )}
      {subtitle && (
        <Title as="p" size={{ _: 'xs', md: 'sm' }} weight="regular">
          {subtitle}
        </Title>
      )}
    </StaggerElements>
  </HeaderContainer>
);

const HeaderContainer = styled.header`
  display: grid;
  grid-auto-flow: row;
  margin: 0 auto;
  row-gap: ${getSpace('normal1')};
  text-align: center;

  ${media.md`
    row-gap: ${getSpace('normal2')};
    max-width: ${pxToRem(646)};
  `}

  ${media.lg`
    max-width: ${pxToRem(808)};
  `}
`;
