import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import type { BoxProps } from '@yoweb/ui/components/Box';

export const ExplanationSectionSliceType = 'explanation_section_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = ['title'] as const; // Array of all required items fields.

export const requiredPrimaryFieldsFeaturedSection = ['imageContainerHeight'] as const;
export const requiredItemsFieldsFeaturedSection = ['image', 'imageHeight', 'imageWidth'] as const;

export const requiredPrimaryFieldsWithBiggerItemFont = [] as const; // Array of all required primary fields.
export const requiredItemsFieldsWithBiggerItemFont = ['title'] as const; // Array of all required primary fields.

type Primary = {
  title?: string;
  subtitle?: RichTextField;
  imageContainerHeight: number;
  ctaHref?: string;
  ctaLabel?: string;
  backgroundColor?: BoxProps['backgroundColor'];
  textColor?: string;
};

type Item = {
  title: string;
  description: RichTextField;
  image: ImageField;
  imageHeight: number;
  imageWidth: number;
  imageHref?: string | null;
};

type PrimaryWithBiggerItemFont = Primary & {
  imageContainerHeight?: number;
};

type ItemWithBiggerItemFont = Pick<Item, 'title' | 'description'>;

type Default = PrismicSlice<
  typeof ExplanationSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;

type WithBiggerItemFont = PrismicSlice<
  typeof ExplanationSectionSliceType,
  CreateRequiredFields<
    ArrayToUnion<typeof requiredPrimaryFieldsWithBiggerItemFont>,
    PrimaryWithBiggerItemFont
  >,
  CreateRequiredFields<
    ArrayToUnion<typeof requiredItemsFieldsWithBiggerItemFont>,
    ItemWithBiggerItemFont
  >,
  'withBiggerItemFont'
>;

type WithFeaturedSection = PrismicSlice<
  typeof ExplanationSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithBiggerItemFont>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFieldsWithBiggerItemFont>, Item>,
  'featuredSection'
>;

export type ExplanationSectionSliceResponse = Default | WithBiggerItemFont | WithFeaturedSection;
