import styled from 'styled-components';
import type { Colors } from 'styled-components';
import type { ReactNode } from 'react';

import { th } from '@yoweb/ui/styles/utils/theme';
import { media } from '@yoweb/ui/styles/utils';

type ContainerWideColorProps = {
  children: ReactNode;
  bgColor: keyof Colors;
} & DataTestId;

/**
 * Wide Container with colored background.
 * Content within defined as children prop as each is unique.
 */
export const ContainerWideColor = ({
  children,
  bgColor = 'evening',
  ...props
}: ContainerWideColorProps) => (
  <Wrapper data-testid="container-wide-color" bgColor={bgColor} {...props}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div<{ bgColor: keyof Colors }>`
  display: grid;
  place-items: center;
  background-color: ${({ bgColor }) => th.getColor(bgColor)};

  ${media.md`
    margin-left: ${th.getSpace('normal3')};
    margin-right: ${th.getSpace('normal3')};
    border-radius: ${th.getRadii('medium')};
  `}
`;

export default ContainerWideColor;
