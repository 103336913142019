import type { RichTextField, ImageField, FilledLinkToWebField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const MediaHeaderSliceType = 'media_header_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  image?: ImageField;
  title?: RichTextField;
  subtitle?: string;
  videoLabel?: string;
  videoLoop?: FilledLinkToWebField;
  videoLoopMobile?: FilledLinkToWebField;
  videoUrl?: FilledLinkToWebField;
  ctaHref?: string;
  ctaLabel?: string;
  ctaSubtitle?: RichTextField;
  ctaAboveMedia?: boolean;
};

export type MediaHeaderSliceResponse = PrismicSlice<
  typeof MediaHeaderSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
