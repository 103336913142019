import type { ReactNode, KeyboardEvent } from 'react';
import { motion, type Transition, type Variants } from 'framer-motion';
import styled from 'styled-components';

import { Text, type TitleProps } from '@yoweb/ui/components/typography';
import { Direction } from './shared';
import type { WithActiveState } from './shared';
import { getDuration, getSpace } from '@yoweb/ui/styles/utils/theme';
import { labelStyles } from '@yoweb/ui/components/typography/Label';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { titleStyles } from '@yoweb/ui/components/typography/Title';
import { StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { isString } from '@yoweb/utils/isString';

const itemContentVariants: Variants = {
  above: {
    opacity: 0,
    height: 0,
    y: -10,
  },
  current: {
    y: 0,
    opacity: 1,
    height: 'auto',
  },
  below: {
    y: 20,
    opacity: 0,
    height: 0,
  },
};
const itemContentBaseTransition: Transition = {
  opacity: {
    duration: 0.4,
    ease: 'easeOut',
  },
  height: {
    duration: 0.6,
    ease: 'easeOut',
  },
  y: {
    duration: 0.3,
    ease: 'easeOut',
  },
};
type ItemContentProps = {
  direction: Direction;
  title: string;
  body: string | ReactNode;
  isInView: boolean;
  keyboardHandler?: (event: KeyboardEvent) => void;
};

const ItemContent = ({ direction, title, body, isInView, keyboardHandler }: ItemContentProps) => {
  const handleKeyboard = (event: KeyboardEvent<any>) => {
    if (keyboardHandler) {
      keyboardHandler(event);
    }
  };

  return (
    <Content>
      <DesktopContent
        role="listbox"
        onKeyDown={handleKeyboard}
        tabIndex={0}
        aria-expanded={direction === 'current'}
      >
        <StaggerElements isInView={isInView}>
          <ItemTitle size={{ md: 'xs', lg: 'md' }} isActive={direction === Direction.Current}>
            {title}
          </ItemTitle>
          <motion.div
            key="content"
            initial="below"
            animate={direction}
            variants={itemContentVariants}
            transition={itemContentBaseTransition}
          >
            <Text as={isString(body) ? 'p' : 'div'} variant="muted" size={{ md: 'sm', lg: 'xl' }}>
              {body}
            </Text>
          </motion.div>
        </StaggerElements>
      </DesktopContent>
      <MobileContent>
        <StaggerElements isInView={isInView}>
          <ItemTitle size={{ _: 'sm' }} isActive={direction === Direction.Current}>
            {title}
          </ItemTitle>
          <Text as={isString(body) ? 'p' : 'div'} variant="primary" size={{ _: 'md' }}>
            {body}
          </Text>
        </StaggerElements>
      </MobileContent>
    </Content>
  );
};

const ItemTitle = styled.h3<TitleProps & WithActiveState>`
  ${titleStyles};

  margin-bottom: ${getSpace('normal1')};
  position: relative;
  padding: 1px 0;

  ${media.md<WithActiveState>`
    ${({ isActive }) => !isActive && labelStyles};

    opacity: ${({ isActive }) => (isActive ? 1 : 0.42)};
    text-transform: ${({ isActive }) => (isActive ? undefined : 'uppercase')};
    transition: opacity ${getDuration('visual')}ms;

    &::before {
      background: currentColor;
      content: '';
      left: -${getSpace('normal2')};
      position: absolute;
      top: 0;

      // Note: these are one-off sizes that are not defined in the design system.
      border-radius: ${pxToRem(2)};
      bottom: ${({ isActive }) => pxToRem(isActive ? 4 : 0)};
      width: ${pxToRem(4)};
    }
  `}
`;

const Content = styled.div`
  grid-column: 1 / span 4;
  padding: ${getSpace('normal4')} 0 ${getSpace('medium2')};

  ${media.md`
    grid-column: 5 / span 4;
    padding: 0 0 0 17.2%;
  `}

  ${media.lg`
    margin: 0;
    grid-column: 7 / span 6;
  `}
`;

const DesktopContent = styled.div`
  display: none;

  ${media.md`
    display: block;
    
    &:focus {
      outline: none;
    }
  `}
`;
const MobileContent = styled.div`
  display: block;

  ${media.md`
    display:none;
  `}
`;

export default ItemContent;
