import type { ImageField } from '@prismicio/types';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { Title, type ColorType } from '@yoweb/ui/components/typography';
import { Container } from '@yoweb/ui/components/containers';
import { StaggerElements, AnimateWhenInView, InView } from '@yoweb/ui/components/AnimateIn';
import { Box } from '@yoweb/ui/components/Box';
import type { BoxProps } from '@yoweb/ui/components/Box';

type FeaturedSectionProps = {
  title?: string;
  backgroundColor?: BoxProps['backgroundColor'];
  textColor?: ColorType;
  featuredItems: {
    imageWidth: number;
    imageHeight: number;
    image?: ImageField;
    imageHref?: string | null;
  }[];
} & DataTestId;
const FeaturedSection = ({
  title,
  backgroundColor,
  featuredItems,
  textColor,
  ...props
}: FeaturedSectionProps) => (
  <Container backgroundColor={backgroundColor} data-testid={props['data-testid']}>
    <InView>
      {(isInView) => (
        <Box pt={'large3'} pb={'large3'}>
          {title && (
            <AnimateWhenInView>
              <Box pb={{ _: 'medium1', md: 'large1', lg: 'large3' }}>
                <Title
                  size={{ _: 'md', lg: 'lg' }}
                  data-testid="featured-explanation-section-title"
                  textAlign="center"
                  variant={textColor}
                >
                  {title}
                </Title>
              </Box>
            </AnimateWhenInView>
          )}

          <Box
            data-testid={`featured-explanation-section-item-container`}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            rowGap="medium1"
            columnGap="normal1"
            alignItems="center"
          >
            <StaggerElements isInView={isInView}>
              {featuredItems.map((item) =>
                item?.image?.url ? (
                  <Box
                    key={item?.image?.url}
                    display="flex"
                    flex={{ _: 0, md: 1 }}
                    justifyContent={{ _: 'center', md: 'space-between' }}
                    mx="normal1"
                    width={item.imageWidth}
                    height={item.imageHeight}
                  >
                    {item.imageHref ? (
                      <Link
                        href={item.imageHref}
                        passHref
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={item.image.url}
                          layout="intrinsic"
                          objectFit="contain"
                          width={item.imageWidth}
                          height={item.imageHeight}
                        />
                      </Link>
                    ) : (
                      <Image
                        src={item.image.url}
                        layout="intrinsic"
                        objectFit="contain"
                        width={item.imageWidth}
                        height={item.imageHeight}
                      />
                    )}
                  </Box>
                ) : null,
              )}
            </StaggerElements>
          </Box>
        </Box>
      )}
    </InView>
  </Container>
);

export { FeaturedSection };
