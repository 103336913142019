import styled from 'styled-components';
import Image from 'next/legacy/image';
import { useContext, type ReactNode } from 'react';

import { Box } from '@yoweb/ui/components/Box';
import { Text } from '@yoweb/ui/components/typography';
import { pxToRem } from '@yoweb/ui/styles/utils';
import { isLinkInternal, shouldLinkOpenInNewTab } from '@yoweb/utils/common';
import { StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { useMobileOrTablet } from '@yoweb/ui/components/Responsive';
import { LinkContext } from '@yoweb/ui/components/Link';
import type { ParseKeys, Namespace } from '@yoweb/next-i18next';

export type ExplanationItemType<T extends Namespace> = {
  description?: ParseKeys<T>;
  image?: string;
  imageHeight?: number;
  imageHref?: string;
  imageWidth?: number;
  key?: string;
  title?: ParseKeys<T>;
};

export type TranslatedExplanationItem = {
  description?: ReactNode | string;
  image?: string;
  imageHeight?: number;
  imageHref?: string;
  imageWidth?: number;
  key?: string;
  title?: string;
  isFeatured?: boolean;
};

export type ExplanationItemProps = TranslatedExplanationItem & {
  imageContainerHeight?: number;
  isInView: boolean;
  delay: number;
} & DataTestId;

const DEFAULT_IMAGE_HEIGHT = 150;
const DEFAULT_IMAGE_WIDTH = 150;

/**
 * Transparent Card component with illustration, title and description
 */
export const ExplanationItem = ({
  image,
  title,
  description,
  imageHeight = DEFAULT_IMAGE_HEIGHT,
  imageWidth = DEFAULT_IMAGE_WIDTH,
  imageHref,
  imageContainerHeight,
  isInView,
  delay,
  isFeatured,
  ...rest
}: ExplanationItemProps) => {
  const baseUrl = useContext(LinkContext);
  const isDesktop = !useMobileOrTablet();

  if (!title && !description && !image) return null;

  let openInNewTab = false;

  if (imageHref) {
    openInNewTab = !isLinkInternal(imageHref, baseUrl) && shouldLinkOpenInNewTab(imageHref);
  }

  const getImageJsx = () =>
    image && (
      <Image
        src={image}
        layout="intrinsic"
        objectFit={isFeatured && isDesktop ? 'cover' : 'contain'}
        objectPosition={isFeatured && isDesktop ? 'center' : 'bottom'}
        height={isFeatured && isDesktop ? imageContainerHeight : imageHeight}
        width={imageWidth}
      />
    );

  return (
    <ItemContainer imageContainerHeight={imageContainerHeight} data-testid={rest?.['data-testid']}>
      <StaggerElements isInView={isInView} initialDelay={delay}>
        {image &&
          (imageHref ? (
            <ImageWrapper
              as="a"
              href={imageHref}
              imageHeight={imageHeight}
              rel={openInNewTab ? 'noopener noreferrer' : ''}
              target={openInNewTab ? '_blank' : ''}
            >
              {getImageJsx()}
            </ImageWrapper>
          ) : (
            <ImageWrapper imageHeight={imageHeight}>{getImageJsx()}</ImageWrapper>
          ))}

        {title && <ItemTitle data-testid="explanation-item-title">{title}</ItemTitle>}

        {description && typeof description === 'string' && (
          <Box mt={20}>
            <Text
              size={{ _: 'lg', md: 'xl' }}
              variant="muted"
              data-testid="explanation-item-description"
            >
              {description}
            </Text>
          </Box>
        )}

        {description && typeof description !== 'string' && <Box mt={20}>{description}</Box>}
      </StaggerElements>
    </ItemContainer>
  );
};

const ImageWrapper = styled.div<{ imageHeight: number }>`
  align-self: center;
  height: ${({ imageHeight }) => pxToRem(imageHeight)};
  position: relative;
  width: 100%;
`;

const ItemContainer = styled.li<{ imageContainerHeight?: number }>`
  display: grid;
  grid-template-rows: ${({ imageContainerHeight }) =>
    `${imageContainerHeight ? pxToRem(imageContainerHeight) : 'auto'} auto auto`};
  justify-items: center;
  max-width: ${pxToRem(392)};
  align-content: start;
  text-align: center;
`;

const ItemTitle = styled.p`
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
  font-weight: 500;
  max-width: ${pxToRem(250)};

  && {
    white-space: pre-line;
  }
`;
