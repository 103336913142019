import { useState, type ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import Image, { type StaticImageData } from 'next/legacy/image';

import { VideoModal } from '@yoweb/ui/components/Media/VideoModal';
import { getColor, getRadii, getSize, getSpace, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { Button, ButtonIcon } from '@yoweb/ui/components/buttons';
import { PlayIcon } from '@yoweb/ui/components/Icon/PlayIcon';
import { useMobileOnly } from '@yoweb/ui/components/Responsive';

export type MediaHeroProps = {
  image?: string | StaticImageData | null;
  imageAltText?: string;
  videoUrl?: string;
  videoLabel?: string;
  videoLoop?: string;
  videoLoopMobile?: string;
  children?: ReactNode;
  mobilePosition?: string;
  centeredButton?: boolean;
};

/**
 * Component that renders full width image in 3:2 aspect ratio and has an optional video that opens up in a modal
 */
export const MediaHero = ({
  image,
  imageAltText,
  videoUrl = '',
  videoLabel,
  videoLoop,
  videoLoopMobile,
  children,
  mobilePosition = 'center',
  centeredButton = false,
}: MediaHeroProps) => {
  const [computedVideoSrc, setComputedVideoSrc] = useState<string | undefined>(undefined);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const isMobile = useMobileOnly();

  useEffect(() => {
    // Note: intentionally computed lazily on the client to avoid unnecessary large video downloads.
    // This is needed because `<source>` media queries are not reliably implemented across browsers and we want to avoid
    // streaming large videos that go unseen on mobile.
    setComputedVideoSrc(isMobile ? videoLoopMobile || videoLoop : videoLoop);
  }, [videoLoop, videoLoopMobile, isMobile]);

  return (
    <StyledContainer data-testid="media-hero" videoLoop={!!videoLoop}>
      {image && (
        <BackgroundImage data-testid="media-hero-image">
          <Image
            src={image}
            layout="fill"
            objectFit="cover"
            objectPosition={`${isMobile ? mobilePosition : 'center'}`}
            priority
            alt={imageAltText}
          />
        </BackgroundImage>
      )}
      {computedVideoSrc && (
        <Video autoPlay muted playsInline loop>
          <source src={computedVideoSrc} type="video/mp4" />
        </Video>
      )}
      <Content>
        {children}
        {videoUrl && !centeredButton && (
          <VideoButton>
            <Button
              mode="dark"
              onClick={() => setVideoModalOpen(true)}
              iconLeft={<PlayIcon />}
              size={{ _: 'sm', md: 'md', lg: 'lg' }}
              data-testid="media-hero-video-button"
            >
              {videoLabel}
            </Button>
          </VideoButton>
        )}
        {videoUrl && centeredButton && (
          <CenteredVideoButton>
            <StyledButtonIcon
              onClick={() => setVideoModalOpen(true)}
              icon={<PlayIcon />}
              data-testid="media-hero-video-button"
            >
              {videoLabel}
            </StyledButtonIcon>
          </CenteredVideoButton>
        )}
      </Content>
      {videoUrl && (
        <VideoModal
          isOpen={isVideoModalOpen}
          onClose={() => setVideoModalOpen(false)}
          videoUrl={videoUrl}
          className="video-modal-wrapper"
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.section<{ videoLoop: boolean }>`
  height: 0;
  overflow: hidden;
  padding-top: ${pxToRem(404)};
  position: relative;
  border-radius: ${getRadii('medium')};
  z-index: ${getZIndex('layer')};

  ${media.sm<{ videoLoop: boolean }>`
    padding-top: ${({ videoLoop }) => (videoLoop ? '56%' : '65%')};
  `}

  ${media.md`
    margin: 0 -${getSize('gridGap')};
  `}

  ${media.lg`
    margin: 0;
  `}
`;

const BackgroundImage = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Content = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: ${getZIndex('above')};
  width: 100%;
`;

const VideoButton = styled.div`
  bottom: ${getSpace('normal1')};
  left: ${getSpace('normal1')};
  position: absolute;

  ${media.sm`
    bottom: ${getSpace('normal3')};
    left: ${getSpace('normal3')};
  `}

  ${media.lg`
    bottom: ${getSpace('medium1')};
    left: ${getSpace('medium1')};
  `}
`;

const CenteredVideoButton = styled.div`
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButtonIcon = styled(ButtonIcon)`
  && {
    background-color: ${getColor('sprout')};

    :hover {
      background-color: ${getColor('successLight')};
    }
  }
`;

const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: black;

  ${media.md`
    width: 100%;
    height: auto;
    background: transparent;
  `}
`;
