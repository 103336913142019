import { AnimatePresence, motion, type Transition, type Variants } from 'framer-motion';
import styled from 'styled-components';

import { Text, Title } from '@yoweb/ui/components/typography';
import { getColor, getRadii, getSpace, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { useMobileOnly } from '@yoweb/ui/components/Responsive';
import { DURATIONS, EASINGS } from '@yoweb/ui/components/AnimateIn';

type ChatMessageProps = {
  initials?: string | null;
  message?: string | null;
  isActive: boolean;
  isInView: boolean;
};

const repVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};
const repTransition: Transition = {
  ease: EASINGS.easeOut,
  delay: DURATIONS.d25,
  duration: DURATIONS.d25,
  bounce: 0,
};

const messageVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const messageTransition: Transition = {
  opacity: {
    ease: EASINGS.easeOut,
    delay: 0.5,
    duration: DURATIONS.d25,
  },
  scale: {
    type: 'spring',
    delay: 0.5,
    bounce: 0.15,
    duration: 0.4,
  },
};

const origin = {
  /* stylelint-disable-next-line property-no-unknown */
  originX: 0,
  /* stylelint-disable-next-line property-no-unknown */
  originY: 1,
};

const ChatMessage = ({ isActive, initials, message, isInView }: ChatMessageProps) => {
  const mobileOnly = useMobileOnly();
  const renderChat = (mobileOnly || isActive) && isInView;

  if (!initials || !message) {
    return null;
  }

  return (
    <div aria-hidden="true">
      <AnimatePresence>
        {renderChat && (
          <Rep>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={repVariants}
              transition={repTransition}
            >
              <RepImage>
                <Title as="span" variant="inverse" size="sm">
                  {initials}
                </Title>
              </RepImage>
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={messageVariants}
              transition={messageTransition}
              style={origin}
            >
              <RepQuote>
                <Text>{message}</Text>
              </RepQuote>
            </motion.div>
          </Rep>
        )}
      </AnimatePresence>
    </div>
  );
};

const Rep = styled.div`
  align-items: end;
  bottom: 0;
  display: grid;
  grid-auto-flow: column;
  padding: ${getSpace('normal3')} 0;
  grid-gap: ${getSpace('normal1')};
  justify-content: start;
  left: 0;
  position: absolute;
  right: 0;
  z-index: ${getZIndex('layer')};

  ${media.md`
    padding: ${getSpace('normal3')};
  `}
`;

const RepImage = styled.div`
  align-items: center;
  background: ${getColor('base900')};
  border-radius: 50%;
  height: ${pxToRem(48)};
  display: flex;
  justify-content: center;
  width: ${pxToRem(48)};
  text-align: center;

  ${media.md`
    height: ${pxToRem(60)};
    width: ${pxToRem(60)};
  `}
`;

const RepQuote = styled.div`
  background: ${getColor('base000')};
  border-radius: ${getRadii('medium')};
  border-bottom-left-radius: 0;
  padding: ${getSpace('normal2')};

  ${media.md`
    max-width: ${pxToRem(340)};
  `}
`;

export default ChatMessage;
