import type { RichTextField, ImageField } from '@prismicio/types';
import type { ReactNode } from 'react';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import type { ButtonVariant } from '@yoweb/ui/components/buttons/Button';

export const VerticalScrollCarouselSliceType = 'vertical_scroll_carousel_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredPrimaryFieldsWithTitle = ['title'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['title'] as const; // Array of all required items fields.

type Primary = {
  ctaHref?: string;
  ctaLabel?: string;
  useWhitePaperCta?: boolean;
  buttonVariant?: ButtonVariant;
  whitePaperModal?: ReactNode;
  openModal?: () => void;
  navId?: string;
};

type PrimaryWithTitle = {
  title: string;
  subtitle?: RichTextField;
  ctaHref?: string;
  ctaLabel?: string;
  useWhitePaperCta?: boolean;
  buttonVariant?: ButtonVariant;
  whitePaperModal?: ReactNode;
  openModal?: () => void;
  navId?: string;
};

type Item = {
  title: string;
  subtitle: RichTextField;
  initials?: string | null;
  quote?: string | null;
  label?: RichTextField;
  image: ImageField;
  mobileImage?: ImageField;
};

type Default = PrismicSlice<
  typeof VerticalScrollCarouselSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;

type WithTitle = PrismicSlice<
  typeof VerticalScrollCarouselSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithTitle>, PrimaryWithTitle>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'withTitle'
>;

export type VerticalScrollCarouselSliceResponse = Default | WithTitle;
