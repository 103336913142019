import styled, { css } from 'styled-components';
import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  MouseEventHandler,
  ElementType,
} from 'react';

import { Interactive, type TextColorProps } from '@yoweb/ui/components/typography';
import type { TextBaseProps } from '@yoweb/ui/components/typography/textBase';

export const buttonLinkSizes = ['sm', 'md', 'lg', 'xl'] as const;
export type ButtonLinkSize = (typeof buttonLinkSizes)[number];

type SharedProps = {
  as?: ElementType;
  disabled?: boolean;
  href?: string;
  size?: ButtonLinkSize;
  text?: string;
  weight?: TextBaseProps['weight'];
  openInNewTab?: boolean;
  withUnderline?: boolean;
};

interface ButtonProps<T> extends ButtonHTMLAttributes<T>, TextColorProps, SharedProps, DataTestId {
  onClick?: MouseEventHandler<T>;
}

interface LinkProps<T> extends AnchorHTMLAttributes<T>, TextColorProps, SharedProps, DataTestId {
  onClick?: MouseEventHandler<T>;
}

export type ButtonLinkProps<T> = LinkProps<T> | ButtonProps<T>;

export const ButtonLink = ({
  children,
  onClick,
  text,
  href,
  as,
  size = 'lg',
  variant = 'fg',
  openInNewTab = false,
  withUnderline = true,
  ...props
}: ButtonLinkProps<HTMLAnchorElement | HTMLButtonElement>) => {
  const tag = href === undefined ? 'button' : 'a';
  const buttonProps = {
    ...(openInNewTab && {
      rel: 'noopener noreferrer',
      target: '_blank',
    }),
    ...props,
  };

  return (
    <StyledInteractive
      as={as ?? tag}
      href={href}
      onClick={onClick}
      size={size}
      textDecoration={withUnderline ? 'underline' : undefined}
      variant={variant}
      {...buttonProps}
    >
      {text || children}
    </StyledInteractive>
  );
};

const StyledInteractive = styled(Interactive)<
  ButtonLinkProps<HTMLAnchorElement | HTMLButtonElement>
>`
  && {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.42;
      `}
  }
`;
