import styled, { css } from 'styled-components';
import Link from 'next/link';

import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { Title } from '@yoweb/ui/components/typography';
import { Button } from '@yoweb/ui/components/buttons/Button';
import { Box, type BoxProps } from '@yoweb/ui/components/Box';
import { ExplanationItem } from './ExplanationItem';
import { Container } from '@yoweb/ui/components/containers';
import { AnimateWhenInView, InView, STAGGER_DELAY } from '@yoweb/ui/components/AnimateIn';
import type { TranslatedExplanationItem } from './ExplanationItem';

export type ExplanationSectionProps = {
  backgroundColor?: BoxProps['backgroundColor'];
  ctaHref?: string;
  ctaLabel?: string;
  ctaOnClick?: () => void;
  imageContainerHeight?: number;
  items: TranslatedExplanationItem[];
  title?: string;
  subtitle?: string | ReactNode;
} & DataTestId &
  Pick<BoxProps, 'backgroundColor' | 'pt' | 'pb' | 'py'>;

export const ExplanationSection = ({
  backgroundColor,
  ctaHref,
  ctaLabel,
  ctaOnClick,
  imageContainerHeight,
  items,
  pb = { _: 'large3' },
  pt,
  py,
  title,
  subtitle,
  ...props
}: ExplanationSectionProps) => (
  <div data-testid={props['data-testid']}>
    <InView>
      {(isInView) => (
        <Container backgroundColor={backgroundColor}>
          <StyledContainer pt={pt} pb={pb} py={py}>
            {title && (
              <AnimateWhenInView>
                <TitleContainer mb={!subtitle ? { _: 'medium2', md: 'medium3', lg: 'large1' } : {}}>
                  <Title
                    size={{ _: 'md', md: 'lg' }}
                    data-testid={'default-explanation-section-title'}
                    whiteSpace="pre-wrap"
                  >
                    {title}
                  </Title>
                </TitleContainer>
              </AnimateWhenInView>
            )}
            {subtitle && (
              <AnimateWhenInView>
                <SubtitleContainer pb={{ _: 'medium2', lg: 'medium3' }}>
                  <Title
                    size={{ _: 'xs', md: 'sm' }}
                    textAlign="center"
                    weight="regular"
                    as="p"
                    variant="muted"
                    data-testid="explanation-section-subtitle"
                  >
                    {subtitle}
                  </Title>
                </SubtitleContainer>
              </AnimateWhenInView>
            )}

            <ItemsContainer
              data-testid={`default-explanation-section-item-container`}
              rowLength={items.length}
            >
              {items.map((item, i) => (
                <ExplanationItem
                  key={item?.key ? item.key : `${item.title}-${i}`}
                  {...item}
                  delay={title ? STAGGER_DELAY : 0}
                  isInView={isInView}
                  imageContainerHeight={imageContainerHeight}
                  data-testid={`explanation-item-wrapper-${i}`}
                />
              ))}
            </ItemsContainer>

            {(ctaHref || ctaOnClick) && ctaLabel && (
              <Box mt={{ _: 'large1', md: 'medium2' }}>
                {ctaOnClick ? (
                  <Button size="lg" as="a" onClick={ctaOnClick}>
                    {ctaLabel}
                  </Button>
                ) : (
                  <Link href={ctaHref as string} passHref legacyBehavior>
                    <Button size="lg" as="a">
                      {ctaLabel}
                    </Button>
                  </Link>
                )}
              </Box>
            )}
          </StyledContainer>
        </Container>
      )}
    </InView>
  </div>
);

const StyledContainer = styled(Box)`
  && {
    display: grid;
    justify-items: center;
    row-gap: ${getSpace('normal2')};
  }
`;

const TitleContainer = styled(Box)`
  margin: 0 auto;
  text-align: center;
`;

const SubtitleContainer = styled(Box)`
  && {
    max-width: ${pxToRem(300)};

    ${media.lg`
      max-width: ${pxToRem(858)};
    `}
  }
`;

const ItemsContainer = styled.ul<{ rowLength: number }>`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  justify-self: stretch;
  row-gap: ${getSpace('medium2')};

  ${media.lg`
    column-gap: ${getSpace('normal3')};
  `};

  ${({ rowLength }) => css`
    ${media.lg`
      grid-template-columns: repeat(${rowLength}, 1fr);
    `};
  `}
`;
